const isTruthy = field => ( field === 'true' || field === true )
// eslint-disable-next-line import/prefer-default-export
export const Config = {
  HOST : process.env.HOST,
  SITE_ID : process.env.SITE_ID,
  PROJECT_NAME : process.env.PROJECT_NAME || 'cox2m',
  GOOGLE_TAG_MANAGER_ID : process.env.GOOGLE_TAG_MANAGER_ID,
  GOOGLE_ANALYTICS_ID : process.env.GOOGLE_ANALYTICS_ID,
  ENCRYPTION_SALT : process.env.ENCRYPTION_SALT,
  ENCRYPTION_PHRASE : process.env.ENCRYPTION_PHRASE,
  IS_AUTH0_ENABLED : isTruthy( process.env.IS_AUTH0_ENABLED ),
  LVP_HOST : process.env.LVP_HOST,
}
